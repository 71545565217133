.bg-bottom-modal {
    padding: 0 3rem;

    & h5 {
        font-size: 1rem !important;
        margin-bottom: .5rem !important;
        font-weight: 700;
    }

    & p {
        font-size: 1rem !important;
        font-weight: 500 !important;
        margin-bottom: .5rem !important;
        color: #000000 !important;
    }

    & ul {
        margin-top: .5rem !important;
    }

    & small {
        font-size: .875rem !important;
        margin-bottom: 0 !important;

        &:first-child {
            margin-bottom: 1rem !important;
        }
    }

    & button {
        margin-top: 1.5rem !important;
    }
}

@media (max-width: 767px) {
    .bg-bottom-modal {
        padding: 0 2rem;
    
        & h3 {
            margin-bottom: 1rem !important;
        }
    
        & p {
            font-size: .8rem !important;
            font-weight: 600 !important;
            padding: 0 !important;
        }

        & b, li {
            font-size: .8rem !important;
        }
    
        & small {
            font-size: .75rem !important;
            margin-bottom: 0 !important;
    
            &:first-child {
                margin-bottom: 1rem !important;
            }
        }
    
        & button {
            margin-top: 1rem !important;
        }
    }
}