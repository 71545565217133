@import '../../assets/sass/_variables.sass';

.bg-footer-caracol {
    //background-image: url("../../assets/img/bg-footer.png");
    background-color: $primary;
    background-size: cover;
    background-repeat: no-repeat;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;

    & .bg-partners-middle {
        border-left: 3px solid #FFF;
        border-right: 3px solid #FFF;
        padding: 0 2rem;
    }

    & h5 {
        font-size: 1.375rem;
        letter-spacing: 1px;
    }

    & p,
    small,
    a {
        font-size: 1rem;
        letter-spacing: 1px;
    }

    & .img-refit {
        width: 140px;
    }

    & .img-fluid-caracol-primary {
        max-width: 100px;
    }

    & .img-fluid-caracol-secondary {
        max-width: 150px;
    }
}

.icons-footer {
    display: flex;
    justify-content: center;

    & img {
        width: 40px;
    }

    & a:hover{
        & svg{
            color: #FFF !important;
            opacity: 0.7;
        }
    }
}

.false {
    width: 70px;
    filter: invert(100%) sepia(51%) saturate(275%) hue-rotate(184deg) brightness(116%) contrast(100%);
}

@media (min-width: 992px) and (max-width: 1200px) {
    .bg-footer-caracol {

        & h5 {
            font-size: 1rem;
        }

        & p,
        small,
        a {
            font-size: .75rem;
        }

        & h6 {
            font-size: .75rem;
        }

        & .img-fluid-caracol-primary {
            max-width: 75px;
        }
    
        & .img-fluid-caracol-secondary {
            max-width: 130px;
        }
    }

    .icons-footer {
        & svg {
            font-size: 2rem !important;
        }
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .bg-footer-caracol {        

        & .bg-partners-middle {
            padding: 0 2rem;

            & .col-5, .col-4, .col-3, .col-2 {
                width: 50%;
            }
        }

        & .bg-certicate-middle {

            & .col-5, .col-4, .col-3, .col-2 {
                width: 50%;
            }
        }

        & h5 {
            font-size: 1rem;
        }

        & p,
        small,
        a {
            font-size: .7rem;
        }

        & h6 {
            font-size: .7rem;
        }

        & .img-fluid-caracol-primary {
            max-width: 60px;
        }
    
        & .img-fluid-caracol-secondary {
            max-width: 110px;
        }
    }

    .icons-footer {
        & svg {
            font-size: 2rem !important;
        }

        & .col-md-3 {
            width: 35%;
        }
    }

}

@media (max-width: 767px) {
    .bg-footer-caracol {
        //background-image: url("../../assets/img/bg-footer-mobile.png");

        & .bg-partners-middle {
            border-left: none;
            border-right: none;
            padding: 0 2rem;
        }

        & .bg-footer-menu { 
            & h5, p{
                display: flex;
                justify-content: center !important;
            }           
            
            & p, a {
                font-size: 1rem !important;
            }
        }

        & p,
        small,
        a {
            font-size: .75rem;
            letter-spacing: 2px;
            line-height: 1rem;
        }

        & h6 {
            font-size: 1rem;
            letter-spacing: 2px;
            line-height: 1rem;
        }

        & .footer-lgpd {
            line-height: 1rem;
            margin-bottom: .5rem;
            & a {
                font-size: .75rem;
                letter-spacing: 2px;
            }
        }
    }
}

.bt-whatsApp {
    position: fixed;
    width: 60px;
    height: 60px; 
    right: 1rem; 
    bottom: 5.5rem;
    z-index: 100;

    & img {
        width: 60px;
    }
}