h2 {
    /* font-size: calc(1.8rem + .5vw); */
    font-size: calc(18px + 1.2vw);
}

.content-header {
    position: relative;
}

.text-tour {
    color: #FFF;
}

.jumbotron-custom-great:hover{
    background: #22642fde;
    transition: all 500ms;
    h2{
        padding: 0 1rem;
    }
   
    .fading-text-on{       
        display: block;
        padding: 0 1rem 4rem ;
        font-weight: 200;
    }   
    .fading-text-off{        
        display: none;
    }   
    .saiba-mais{        
        width: 100%;
        border-radius: 0 0 50px 50px;
        height: 64px;        
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
        background-color: #174D22;
    } 
}
.jumbotron-custom-small:hover{
    background: #22642fde;
    transition: all 500ms;
    h2{
        padding: 0 1rem;
    }
   
    .fading-text-on{       
        display: block;
        padding: 0 1rem 4rem ;
        font-weight: 200;
    }   
    .fading-text-off{        
        display: none;
    }   
    .saiba-mais{        
        width: 100%;
        border-radius: 0 0 50px 50px;
        height: 64px;        
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
        background-color: #174D22;
    } 
}
.jumbotron-custom-great{   
    h2{
        padding: 0 1rem;
    } 
    .fading-text-on{
        display: none;
    }    
    .fading-text-off{
        display: block;
    }    
    .saiba-mais{
        display: none;
    }
}
.jumbotron-custom-small{   
    h2{
        padding: 0 1rem;
    } 
    .fading-text-on{
        display: none;
    }    
    .fading-text-off{
        display: block;
    }    
    .saiba-mais{
        display: none;
    }
}


.container-tour .slick-prev,
.container-tour .slick-next {
    display: none !important;
}

h2.my-inside {
    font-size: calc(24px + .9vw);
    font-weight: 600 !important;
}

.navbar-icons {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    min-height: 85px;
    bottom: 5px;

    ul .row {
        justify-content: center;
    }

    li>span {
        color: black;
        cursor: default;
    }

    p.flex {
        display: flex;
        flex-direction: column;
        margin: 0;
        cursor: pointer;
    }
}

.viewDetailsButton {
    text-decoration: none;
    color: #aaa;
    font-size: 12px;
}

.seeMoreSection {
    & a, button {
        max-width: 270px;
        width: 100%;
        color: white;
        padding: 0;
        background-color: #707070;

        &:hover {
            color: white !important;
        }
    }
}


.bannerCallToAction {

    a,
    img {
        width: 100%;
        cursor: pointer;
    }
}

.navbar-icons .row {
    flex-wrap: nowrap;

    * {
        width: unset;
    }
}

.nav-pills {

    .col-md-4 {
        padding: 0;
        border-right: 1px solid rgba(112, 112, 112, 0.2);
    }

    .col-md-4:last-child {
        border-right: none;
    }

    li {
        margin: 0 70px;
    }


    span {
        padding: 0;
    }
}

.bg-blog {
    width: 100%;
    height: 780px;
}

.home-video {
    display: flex;
    justify-content: center;

    & iframe {
        width: 992px;
        height: clamp(260px, 55vw, 560px);
    }
}

.bg-home-main-text {
    margin: auto;
    padding: 0 1rem;
    width: 75%;
}

@media (max-width: 1199px) {
    .bg-blog {
        height: 325px;
    }
}

@media (max-width: 768px) {
    .nav-pills li {
        margin: 0 35px;
    }

    .bg-home-main-text {
        margin: auto;
        padding: 0 1rem;
        width: 100%;
    }
   
}

@media (max-width: 480px) {
    .nav-pills li {
        margin: 0 20px;
    }
}

/* .bannerCallToAction {
    & img {
        min-height: 170px;
    }
} */

.nav-tour {
    & .btn {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.bannerCall {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat !important;
    height: 205px;
}

.container-caracol-slider .slick-slider {
    width: 80%;
    margin: auto;
}

@media (max-width: 813px) {
    .bannerCall {
        background-size: cover !important;
        background-position: 64% center !important;
        height: 115px;
    }

    .my-inside {
        padding-bottom: 30px;
    }
       

    .my-outside-our-park {
        padding-top: 0 !important;       
    }

    .container-caracol-slider .slick-slider {
        width: 90%;
        margin: auto;
    }
}

@media (max-width: 480px) {
    .container-caracol-slider .slick-slider {
        width: 100%;
        margin: auto;
    }
}


.custom-rounded {
    border-radius: 53px; /* ou 40px, conforme sua preferência */
}

.jumbotron {
    background: linear-gradient(0deg, #174D22 0, rgba(0, 100, 150, 0) 85%);
}

.text-content {
    display: flex;
    flex-direction: column;
    justify-content: end;
    height: 100%;
}

.text-content h2 {
    color: #FF6600;
    font-weight: 600;
    font-size: 64px;
}

.text-content p {
    font-weight: 600;
    font-size: 20px;
}

.text-content-small {
    display: flex;
    flex-direction: column;
    justify-content: end;
    height: 100%;
}

.text-content-small h2 {
    color: #FF6600;
    font-weight: 600;
    font-size: 24px;
}

.text-content-small p {
    font-weight: 600;
    font-size: 16px;
}


@media (max-width: 480px) {
    .text-content h2 {
        font-size: 28px;
    }

    .text-content p, .text-content-small p {
        font-weight: 600;
        font-size: 12px;
    }

    .custom-rounded {
        border-radius: 13px; /* ou 40px, conforme sua preferência */
    }

    .image-mirante{
        height: 400px; /* ou a altura que você preferir */
    
        /* Faz a imagem ocupar 100% da largura disponível */
        width: 100%;
        
        /* Habilita o posicionamento da imagem dentro do container */
        position: relative;
        
        /* Evita que a imagem distorça */
        overflow: hidden;
        border-radius: 13px;
    }

    .image-mirante img {
        /* Ajuste a imagem para preencher o container sem distorcer */
        height: 100%;
        width: auto;
        
        /* Centraliza a imagem se a largura ultrapassar o container */
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        object-fit: cover;
        border-radius: 13px;
    }
}


@media (max-width: 1200px) {
    .jumbotron-custom-great{        
        border-radius: 42px ;        
        .saiba-mais {
            border-radius: 0 0 42px 42px !important;
        }        
    }
    .jumbotron-custom-great:hover{        
        border-radius: 42px ;        
        .saiba-mais {
            border-radius: 0 0 42px 42px !important;
        }        
    }

    .jumbotron-custom-small{        
        border-radius: 38px ; 
    }
    .jumbotron-custom-small:hover{        
        border-radius: 42px ;        
        .saiba-mais {
            border-radius: 0 0 42px 42px !important;
        }        
    }
}


@media (max-width: 998px){
    .jumbotron-custom-great{        
        border-radius: 48px;
       
    }
    .jumbotron-custom-small{         
        border-radius: 40px;        
    }
    .jumbotron-custom-great:hover{        
        border-radius: 40px ;        
        .saiba-mais {
            border-radius: 0 0 40px 40px !important;
        }
        .custom-rounded {
            border-radius: 40px;
        }
    }
    .jumbotron-custom-small:hover{        
        border-radius: 40px ;        
        .saiba-mais {
            border-radius: 0 0 40px 40px !important;
        }
        .fading-text-on{  
            padding: 0 1rem 1rem ;
            font-size: 14px !important;
            margin: 0;
        }  
        .custom-rounded {
            border-radius: 40px;
        }
    }
   
}
@media (max-width: 880px){ 
    .jumbotron-custom-great{        
        border-radius: 30px;       
    }
    .jumbotron-custom-small{         
        border-radius: 30px;        
    }
    
    .jumbotron-custom-great:hover{        
        border-radius: 40px ;        
        .saiba-mais {
            border-radius: 0 0 40px 40px !important;
        }
        .custom-rounded {
            border-radius: 40px;
        }
    }
    .jumbotron-custom-small:hover{        
        border-radius: 30px ;        
        .saiba-mais {
            border-radius: 0 0 40px 40px !important;
        }
        .fading-text-on{  
            padding: 0 1rem 1rem ;
            font-size: 14px !important;
            margin: 0;
        }  
        .custom-rounded {
            border-radius: 40px;
        }
    }
   
}

@media (max-width: 768px) { 

    .jumbotron-custom-great{   
        h2{
            font-size: 3rem !important;
            padding-bottom: 2rem;
        }  
    }
    .jumbotron-custom-great:hover{        
        border-radius: 32px ; 
        .fading-text-on{  
            padding: 0 1rem 2rem ;  
            margin: 0;         
        }       
        .saiba-mais{ 
            border-radius: 0 0 32px 32px;
            height: 70px; 
        }         
    }
    .jumbotron-custom-small{   
        h2{
            font-size: 3rem !important;
            padding-bottom: 2rem;
        }     
        border-radius: 80px !important;           
    }
    .jumbotron-custom-small:hover{
        background: #22642fde;
        transition: all 500ms;
        border-radius: 70px ;
        h2{
            padding: 0 1rem;
            margin-bottom: 5rem;
        }
       
        .fading-text-on{       
            display: block;
            font-size: 20px !important;
            padding: 0 1rem 4rem ;
            font-weight: 200;
        }   
        .saiba-mais{     
            border-radius: 0 0 70px 70px;
            height: 90px;  
        }
    }
}

@media (max-width: 624px){
    .jumbotron-custom-great{   
        h2{
            font-size: 3rem !important;
            padding-bottom: 2rem;
        }  
        border-radius: 24px !important;        
    }
    .jumbotron-custom-great:hover{        
        border-radius: 32px ;
        h2{
            padding-bottom: 0 !important;
           margin: 0;
        }   
        .fading-text-on {
            padding: 1rem;
            margin: 0;
        }    
        .saiba-mais{ 
            border-radius: 0 0 32px 32px;
            height: 90px !important; 
        }         
    }
    .jumbotron-custom-small{  
        border-radius: 64px !important; 
        h2{
            font-size: 3rem !important;
            padding-bottom: 2rem;
        }         
    }
    .jumbotron-custom-small:hover{       
        border-radius: 70px ;
        h2{
            padding: 0 1rem;
            margin-bottom: 1rem;
        }
       
        .fading-text-on{       
            display: block;
            font-size: 20px !important;
            padding: 0 1rem 4rem ;
            font-weight: 200;
        }   
        .saiba-mais{   
            border-radius: 0 0 70px 70px !important;
            height: 90px;  
        }
    }
}
@media (max-width: 512px){    
    .jumbotron h2:not(:hover){
        text-shadow: -1px -1px 0 #000000, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black;
    }

    .jumbotron-custom-great{   
        h2{
            font-size: 2rem !important;
            padding-bottom: 2rem;
        }    
        border-radius: 14px !important;      
    }
    .jumbotron-custom-great:hover{        
        border-radius: 14px ;
        h2{            
            padding: 1rem 1rem 0 !important;
            margin: 0 ;
        }    
        .fading-text-on {
            padding: 1rem;
            font-size: 1rem !important;
        }    
        .saiba-mais{ 
            border-radius: 0 0 14px 14px !important;
            height: 48px !important; 
        }         
    }
    .jumbotron-custom-small{   
        h2{
            font-size: 2rem !important;
            padding-bottom: 2rem;
        } 
        border-radius: 48px !important;         
    }
    .jumbotron-custom-small:hover{        
        border-radius: 42px !important;
        h2{
            padding: 0 1rem;  
            margin: 0;          
        }
       
        .fading-text-on{       
            display: block;
            font-size: 1rem !important;
            padding: 1rem ;
            font-weight: 200;
            margin: 0;
        }   
        .saiba-mais{     
            border-radius: 0 0 42px 42px !important;
            height: 64px;  
        }
    }
}

@media (max-width: 397px) {   
    .jumbotron-custom-small{  
        border-radius: 30px !important;      
    }
}