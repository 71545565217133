@import '../../../assets/sass/_variables.sass';

.loading-modal .load {
    width: 100px;
    height: 100px;
    margin: 0 auto;
    border: solid 8px $primary;
    border-radius: 50%;
    border-right-color: transparent;
    border-bottom-color: transparent;
    -webkit-transition: all 0.5s ease-in;
    -webkit-animation-name: rotate;
    -webkit-animation-duration: 1.0s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    transition: all 0.5s ease-in;
    animation-name: rotate;
    animation-duration: 1.0s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}