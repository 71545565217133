@import '../../assets/sass/_variables.sass';

.top-park {
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: cover;
    height: 260px;
    max-height: 260px;
    & .shadow-park {
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    & h1 {
        text-transform: uppercase;
        color: #FFF;
        font-weight: 700;
        border-bottom: 3px solid $primary;
        border-top: 3px solid $primary;
    }
}

.map-park {
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: cover;
    height: 450px;
    max-height: 450px;
    & .shadow-map-park {
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
    }
    & .title-map-park {
        text-transform: uppercase;
        font-weight: 700;
        color: #FFF;
    }
    & .tourSlider {
        padding-top: 10px;
    }
}

.park-content {
    max-width: 990px;
    margin: auto;

    & ul {
        margin-top: 0 !important;
        margin-bottom: 1rem !important;
        padding-top: .5rem !important;
        padding-left: 2rem !important;

        & li {
            list-style: inside !important;
        }
    }
}

.bg-iframe-park {
    height: 545px;
}

@media (max-width: 820px) {
    .bg-iframe-park {
        height: 420px;
        margin-bottom: 3rem;
    }
}

@media (max-width: 600px) {
    .bg-iframe-park {
        height: 300px;
        margin-bottom: 3rem;
    }
}

@media (max-width: 500px) {
    .bg-iframe-park {
        height: 260px;
        margin-bottom: 2rem;
    }
}

@media (max-width: 400px) {
    .bg-iframe-park {
        height: 205px;
        margin-bottom: 2rem;
    }
}