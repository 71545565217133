@import '../../assets/sass/_variables.sass';

.img-store {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 100%;
    height: 100%;
    max-height: 130px;
    max-width: 200px;
    margin: auto;
}

.store-list {
    padding: 0;

    & .card {
        padding: 20px;
        color: #707070;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    }

    & .card {
        height: 176px;
    }

    & p {
        font-size: 1rem;
        margin-bottom: 0;
    }

    & .fa-1x {
        font-weight: 700;
    }
}

.paginate-btn {
    font-weight: 600;
    background-color: $primary;
    border: none;
    border-radius: 50%;
    margin: 0 10px 0 10px;
    padding: 5px 8px;
    display: flex;
    align-items: center;

    &:hover {
        opacity: .6;
    }
}

@media(max-width: 991px) {
    .store-list {
        & .card {
            height: 180px;
        }

        & .row {
            height: 100%;
        }
    }
}

@media(max-width: 550px) {
    .img-store {
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        width: 100%;
        height: 100%;
        max-width: 140px;
        margin: auto;
    }

    .store-list {
        & .card {
            height: 200px;
        }

        & .row {
            height: 100%;
            margin-top: auto;
            margin-bottom: auto;
        }
    }
}

@media(max-width: 413px) {
    .store-list {
        & .card {
            height: auto;
        }

        & .row {
            height: 100%;
        }
    }
}

.store_social_midia {
    display: flex;
    gap: .5rem;

    & svg:hover {
        opacity: .75;
    }
}