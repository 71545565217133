.header-sticky {
    position: sticky;
    top: -0.5px;
    z-index: 100;
    width: 100%;
}

#header.hide {
    opacity: 0;
}

@media (max-width: 1024px) {
    .brand-parceiros {
        margin-left: 1rem;
    }
}

@media (max-width: 767px) {
    .brand-parceiros {

        & img {
            max-width: 100% !important;
        }
    }

    .col-parceiros {
        max-width: 115px;
    }

    .acesso-parceiros {
        font-size: .875rem;
    }
}