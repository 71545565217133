@media (min-width: 1400px) {
    .explore-section {
        margin: 0 7.5vw;
    }   
}

@media (min-width: 1200px) and (max-width: 1399px) {
    .explore-section {
        margin: 0 3.5vw;
    }  
}

.gradient {
    position: relative;
    width: 300px;
    height: 300px;
}

.gradient:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: "";
    background: linear-gradient(0deg, rgba(0, 0, 0, .9), rgba(0, 0, 0, .5) 40%, rgba(0, 0, 0, .2) 80%, rgba(0, 0, 0, 0));
    z-index: 1;
    border-radius: 10px;
}

.gradient img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

.gradient-text {
    position: absolute;
    bottom: 10px;
    left: 10px;
    color: white;
    z-index: 2;
}

.explore-unit {
    display: flex;
    justify-content: center;
}