p.text-newsletter {
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1em;
}

.bg-newsletter {
    background-size: 100%;
    background-repeat: no-repeat;
    min-height: 1000px;
    display: flex;
    align-items: flex-end;
    background-position: 50% 10%;
    & input {
        width: 100%;
        padding: 10px;
        border-radius: 10px;
        font-size: 1.25rem;
        font-weight: 700;
        color: #999999;
        padding-left: 20px;
        border: 0;
        &::placeholder {
            font-weight: 700;
            color: #999999;
        }
    }
    & button.btn-newsletter {
        width: 100%;
        padding: 10px;
        border-radius: 10px;
        background-color: #FFCF01;
        color: #FFF;
        font-weight: 700;
        text-transform: uppercase;
        font-size: 1.25rem;
        border: 0;
    }
}

.bg-newsletter-bottom {
    background: linear-gradient(360deg, rgba(1,0,10,1) 65%, rgba(0,0,0,0) 101%, rgba(0,4,5,1) 100%);
    width: 100%;
}

@media (max-width: 530px) {
    .bg-newsletter-bottom {
        background: linear-gradient(360deg, #01000aa1 65%, rgba(0, 0, 0, 0) 101%, #00040554 100%);
        width: 100%;
    }
}

.text-newsletter {
    text-align: center;
    color: #FFF;
}

.newsletter-title {
    color: #FFCF01;
    font-weight: 700;
    text-transform: uppercase;
}

@media (max-width: 1600px) {
    .bg-newsletter {
        min-height: 840px;
    }
}

@media (max-width: 1024px) {
    .bg-newsletter {
        min-height: 645px;
    }
}

@media (max-width: 890px) {
    .bg-newsletter {
        background-size: cover;
        min-height: 100% ;
        margin-top: 60px ;
    }
}

.poweredWrapper {
    display: none;
}