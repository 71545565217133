.atividadesDestacadas .card.rounded.overflow-hidden {
    margin: 0px 10px;
    max-width: 310px;
    min-height: 504px;
    margin: auto;
}


@media (max-width: 480px) {
    .atividadesDestacadas .card.rounded.overflow-hidden {
        margin: auto;
        min-width: auto;
        min-height: auto;
        max-width: 280px;
    }

    .atividadesDestacadas {
        & .slick-prev {
            left: 0;
        }

        & .slick-next {
            right: 0;
        }
    }
}


.activ-img {
    height: 230px;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.card {
    border: none;
    /* 
    &.card-slider {
        & .card-body {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    } */
}

.tourName {
    height: 100% !important;
    //min-height: 38px !important;
    overflow: hidden;
    margin-bottom: 0;
}



@media (max-width: 1399px) {
    .tourName {
        //min-height: 45px !important;
        overflow: hidden;
    }
}

.tourDescript {
    font-size: 12px;
    margin: 0;
    max-height: 20px;
    overflow: hidden;
}

.product-slider {
    & a {
        text-decoration: none;

        & .tourDescript {
            color: #707070;
        }
    }

    & .btn {
        color: white;
        font-size: 20px;
        text-transform: uppercase;
        font-weight: 800;
        padding: 15px 30px;
        min-width: 155px;
    }
}

.container.container-atividades h2 {
    text-transform: uppercase;
    font-weight: 700;
}

.card-slider {
    //border-bottom: 10px solid #E9E9E9;
    transition: all .2s;
    cursor: pointer;
}

@media (max-width: 480px) {
    /* .atividadesDestacadas{
        padding: 0px 25px;

    } */

    .container.container-atividades h2 {
        text-transform: uppercase;
        font-weight: 700;
    }

    
    div#buy-product {
        position: relative;
        padding-left: 0px;
        padding-right: 0px;
    }

    .slick-slider.pb-3.slick-initialized:before {
        width: 40px;
        height: 93%;
        background-image: linear-gradient(to right, rgba(255, 255, 255, 0), #eeeeee60 20%, #fff);
        content: "";
        z-index: 1;
        position: absolute;
        transform: rotate(-180deg);
        left: 0px;
    }

    .slick-slider.pb-3.slick-initialized:after {
        width: 40px;
        height: 93%;
        background-image: linear-gradient(to right, rgba(255,255,255,0), #eeeeee60 20%, #fff);
        content: "";
        z-index: 0;
        position: absolute;
        top: 0;
        right: 0px;
    }
}

.tourDescript {
    & .svg-inline--fa .fa-secondary {
        opacity: 0.6;
    }

    & .svg-inline--fa .fa-primary {
        fill: white;
        opacity: 1;
        opacity: var(--fa-primary-opacity, 1);
    }
}

.buy-btn {
    .btn {
        padding: 0.375rem 0.75rem !important;
        ;
    }
}


@media (min-width: 1200px) {
    .atividadesDestacadas {
        & .slick-list {
            max-height: 100% !important;

            & .slick-track {
                width: 100% !important;
                height: 560px;
            }
        }

        & .slick-slide {
            width: 25% !important;
            //height: auto;
        }
    }
}

@media (min-width: 768px) and (max-width: 1199px) {
    .atividadesDestacadas {
        & .slick-list {
            max-height: 100% !important;

            & .slick-track {
                width: 100% !important;
                height: 560px;
            }
        }

        & .slick-slide {
            width: 33.33% !important;
            //height: auto;
        }
    }
}

@media (min-width: 576px) and (max-width: 767px) {
    .atividadesDestacadas {
        & .slick-list {
            max-height: 100% !important;

            & .slick-track {
                width: 100% !important;
                height: 560px;
            }
        }

        & .slick-slide {
            width: 50% !important;
            //height: auto;
        }
    }
}

@media (max-width: 575px) {
    .product-content {
        min-height: 230px !important;
    }

    .slick-slider .slick-list {
        max-height: 565px !important;
    }

    .atividadesDestacadas .slick-arrow {
        display: block !important;
        //background-color: $primary;
    }

    .atividadesDestacadas {

        & .slick-next,
        .slick-prev {
            &:before {
                filter: invert(44%) sepia(21%) saturate(5230%) hue-rotate(0deg) brightness(101%) contrast(101%);
            }
        }
    }
}

.product-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 115px !important;
}

.atividadesDestacadas .slick-track {
    height: 530px;
}