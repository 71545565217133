@media (max-width: 767px) {
    .banner-home .container {
        height: 150px;

        & .row {
            overflow: hidden;
            width: 90%;
            margin: auto;

            & img {
                width: auto;
                max-width: unset;
                height: 150px;
                position: relative;
                left: -50%;
            }
        }
    }
}

.bg-main-banner {
    //max-height: 450px;
    & .slick-next {
        right: 0;
    }

    & .slick-prev {
        left: 0;
    }

    & .slick-prev,
    .slick-next {
        background-color: transparent !important;
        border: none !important;
    }

    & .slick-prev:hover,
    .slick-next:hover {
        background-color: transparent !important;
        border: none !important;
        opacity: 1;
    }

    & a {
        cursor: pointer;
    }
}


/* .slider-text{
    position: absolute;
    top: 30%;
    left: calc(50% - (2vw * 14));
    width: unset;
    
}

.slider-text h2{
    color: white;
    font-size: 2vw;
    text-align: center;
}

 */
.slider-text {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 50px;
}

.slider-text h2 {
    color: white;
    font-size: calc(8px + 2vw) !important;
    text-align: center;
}


.content-header .slick-slide div {
    max-height: 500px;
}

.content-header .slick-slide {
    position: relative;
    //height: 600px !important;
}

@media (max-width: 480px) {
    .bg-secondary {
        &.banner-home {
            width: 100% !important;
            & img {
                min-height: 250px;
            }
        }
    }
}



/* @media (max-width: 1024px) {
    .content-header .slick-slide {
        height: 400px !important;
    }
}

@media (max-width: 768px) {
    .content-header .slick-slide {
        height: 250px !important;
    }
}

@media (max-width: 450px) {
    .content-header .slick-slide {
        height: 200px !important;
    }
} */