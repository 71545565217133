@import "../../assets/sass/_variables.sass";

.bg-top-affiliates {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    width: 100%;
    height: 480px;
    position: relative;

    & .container {
        display: flex;
        align-items: center;
        height: 100%;
        color: #FFF;

        & h1 {
            text-transform: uppercase;
            margin-bottom: 1.375rem;
        }

        & p {
            line-height: 1.5;
            margin-bottom: 1.375rem;
        }

        & .btn-primary {
            border-radius: 100px;
            font-size: 1rem;
            font-weight: 500;
            height: 48px;
            padding: 0 35px;
            line-height: 48px;
        }
    }

    &:after {
        content: "";
        background-color: #00000085;
        width: 100%;
        position: absolute;
        height: 100%;
        top: 0;
        left: 0;
    }
}

.bg-meddium-affiliates {

    & .container-content {
        & h3 {
            width: 50%;
            text-align: center;
            text-transform: uppercase;
            padding-bottom: 3rem;
        }
    }

    & .reason {
        padding: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        & b {
            margin: 10px 0;
        }

        & p {
            font-size: .875rem;
        }
    }

    & .bg-partners-affiliates {
        padding: 3rem 0;
        background-color: #f8f9fa;

        & h3 {
            padding-bottom: 0rem;
        }

        & p {
            font-size: .875rem;
            margin-bottom: 0;
        }

        & .part-imgs img {
            width: 165px;
            //padding-bottom: 70px;
            margin-left: auto;
            margin-right: auto;
            -webkit-filter: grayscale(100%);
            filter: grayscale(100%);

            &:hover {
                -webkit-filter: none;
                filter: none;
            }
        }
    }
}

.bg-form-affiliates {
    padding: 3rem 0;

    & .forms-affiliates {
        padding: 4rem 0 1rem;
    }

    & .rmdp-container {
        width: 100%;
    }
}

@media (max-width: 767px) {
    /* .bg-header-affiliates {
        & .nav-item.dropdown.nav-item-c2 {
            top: 7.5px;
        }
    } */
    .bg-meddium-affiliates {
        & .container-content {
            & h3 {
                width: 100%;
            }

            & p {
                text-align: center;
            }
        }

        & .part-imgs {
            display: flex;
            padding-bottom: 1rem;
        }
    }
}