.error-cadaster {
    width: 100%;
    color: "red";
    background-color: "#f8d7da";
}

.profile-container .form-control {
    height: calc(2.5rem + 2px);
}

@media (max-width: 405px) {
    .bg-profile-recaptcha {
        width: 106%;
    }
}

.password-strength-container{
    padding: 0 12px;
    gap: 2px;
    margin-top: 10px;
    
    .password-strength{
        border: 2px solid #ccc;
        width: 24%;
    }
}
