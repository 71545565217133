@import '../../../assets/sass/_variables.sass';

.cancel-reservation {
    cursor: pointer;
    text-decoration: underline;
    text-decoration-color: $primary;
    &:hover {
        opacity: .8;
        text-decoration-color: #cc5200;
    }
}