.calendar-message {
    background-color: #f0f0f0;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin: 0 4px;
    max-width: 360px;
    
    .calendar-message-title{
        font-weight: 600;
        font-size: 1.25rem;
        text-align: left;
    }
    .calendar-message-content{        
        text-align: left;
    }
  }
  