@import "../../../../assets/sass/_variables.sass";

step-labels {
    & span {
        position: relative;
        font-weight: 600;

        &:nth-child(1) {
            left: -20px;
        }

        &:nth-child(2) {
            left: -50px;
        }

        &:nth-child(3) {
            left: -70px;
        }

        &:nth-child(4) {
            left: -20px;
        }

        &:nth-child(5) {
            left: 30px;
        }

        &:nth-child(6) {
            left: 23px;
        }
    }
}

.steps .step.complete {
    background-color: $primary;

    & .complete {
        color: $primary;
    }
}

.step-labels span {
    font-size: 16px;
    color: #707070;
}

.steps .stepline {
    width: calc(50% - 45px);
    background-color: #adb5bd;
    height: 5px;
}

.steps .step {
    background-color: #707070;

    & span {
        position: absolute;
        transform: translateX(-50%);
        color: #707070;
        width: auto;
    }
}

.step-labels .complete {
    color: $primary  !important;
    font-weight: 600;
}

.steps .stepline.complete {
    background-color: $primary;
}

.steps-guide.container {
    width: 90%;
}

@media(max-width: 1600px) {

    /*     .steps .step {
        & span {
            width: 95px;
            line-height: 1.5rem;
            margin-top: 5px;
        }
    }
 */
    .suppliers-guide.steps-guide.container,
    .partners-guide.container {
        width: 80% !important;
    }
}

.back-btn {
    margin-right: 20px;
    background-color: transparent;
    color: $primary;
}

.steps .step {
    font-weight: 500;
    background-color: #adb5bd;
    width: 30px;
    height: 30px;
    min-width: 30px;
    text-align: center;
    line-height: 30px;
    color: #fff;
    border-radius: 15px;
}

.steps {
    align-items: center;
}

.bg-form-affiliates {
    & .container-content {

        & .css-1s2u09g-control {
            min-height: 48px !important;
        }

        & .css-1pahdxg-control {
            min-height: 48px !important;
            border-color: #b398ff;
            outline: 0;
            box-shadow: 0 0 0 0.25rem #6731ff40;
        }

        & .form-control,
        select {
            height: 48px;
            border-radius: 5px;
            padding: 12px 20px;
        }

        & .btn-primary,
        .btn-outline-primary {
            border-radius: 100px;
            font-size: 1rem;
            font-weight: 500;
            height: 48px;
            padding: 0 35px;
            width: 100%;
        }

        & .btn-file {
            border: 1px solid #D1D3E2;
            width: 110px;
            border-radius: 0.25rem;
            color: #707070;
            font-size: 14px;
            font-weight: 500;
            height: 38px;
            text-align: center;
            line-height: 34px;
            cursor: pointer;
            margin-right: 10px;

            &:hover {
                opacity: .7;
            }
        }

        & input[type="file"] {
            display: none;
        }
    }
}

@media (max-width: 767px) {
    .steps .step {
        & span {
            width: auto;
            font-size: .8rem;
            line-height: 1.25rem;
            padding-top: .75rem;
        }
    }
}

.password-strength-container {
    padding: 0 12px;
    gap: 2px;
    margin-top: 10px;

    .password-strength {
        border: 2px solid #ccc;
        width: 24%;
    }
}