@import '../../assets/sass/_variables.sass';

.destino h4,
.destino h5 {
    padding: 1rem 0 .5rem;
    margin-bottom: 0;
    text-transform: uppercase;
    font-weight: 400;
}

ul.inclusoes li {
    padding-bottom: 5px;
    list-style-image: url('../../assets/icons/icon-sim.png');
}

ul.exclusoes li {
    padding-bottom: 5px;
    list-style-image: url('../../assets/icons/icon-nao.png');
}

#modalidades ul li {
    font-size: 0.8rem;
}

.destino ul {
    margin-bottom: 0;
    padding: 0 1.2rem;
}

#modalidades .preco {
    background: #dee2e6;
    padding: 1rem;
}

#modalidades .valor strong {
    font-size: 42px !important;
    font-weight: 600;
    line-height: 32px;
}

#escolherdata {
    & .rmdp-container {
        width: 100%;
    }

    & .btn-add-cart {
        width: 100%;
        border-radius: 0;
    }
}

.weekDay{
    color: #fff;
    background-color: transparent;
    border-radius: 0.25rem;    
    font-size: 0.65rem;
    font-weight: 500;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;    
    margin-right: .25rem;
    padding: 0.25rem 0.5rem; 
    min-width: 36.59px;       
}
.enable{
    background-color: $primary;
}
.disable{
    background-color: #707070;
}


@media (max-width: 575px) {
    #escolherdata {
        & .input-group {
            display: grid;
        }
    }
}

.btn-add-cart {
    padding-left: 0;

    & .btn-add-cart-modal {
        width: auto;
        font-size: 1.5rem;
        padding: 5px 25px;
        border: none;
    }

    & .modal-header {
        border: none;

        & button {
            width: auto;
            border-radius: 0;
            border-color: $primary;
            background-color: unset;
            color: #FFF;
            right: 5px;
            position: relative;

            &:hover {
                color: #000;
                background-color: unset;
                border-color: #ff751a;
                color: #FFF;
            }
        }
    }

    & button {
        width: 100%;
        border-radius: 0;
        border-color: $primary;
        background-color: $primary;
        color: #FFF;

        &:hover {
            color: #000;
            background-color: #ff7d26;
            border-color: #ff751a;
            color: #FFF;
        }
    }
}

@media (max-width: 992px) {
    .btn-add-cart {
        padding-left: 0;

        & .btn-add-cart-modal {
            width: auto;
            font-size: 1rem;
            padding: 5px 25px;
            border: none;
        }
    }
}

.bg-option-hours {

    &.panel-dropdown {
        padding: 0.375rem 0.5rem !important;
    }

    & .bg-hours {
        margin-bottom: 0 !important;
        & p {
            display: none;
        }

        & .panel-hours {

            & p {
                display: block !important;
                font-size: 1rem !important;
            }

            & small {
                font-size: .5rem !important;
            }
        }

        & .bg-height-hours {
            height: 20px !important;
            padding: 0 !important;
        }

        & span {
            font-size: 0.875rem !important;
            margin-left: 22px !important;
        }

        & .bg-border-hours {
            border-bottom: none !important;
        }

        & .size-icon-hours {
            font-size: 16px !important;
        }
    }

    & .load {
        width: 18px;
        height: 18px;
        margin: 0 auto;
        border: solid 3px $primary;
        border-radius: 50%;
        border-right-color: transparent;
        border-bottom-color: transparent;
        -webkit-transition: all 0.5s ease-in;
        -webkit-animation-name: rotate;
        -webkit-animation-duration: 1.0s;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-timing-function: linear;
        transition: all 0.5s ease-in;
        animation-name: rotate;
        animation-duration: 1.0s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        bottom: 2px !important;
    }
}

.bg-cancel-rules {

    & h6 {
        font-weight: 700;
    }

    & ul {
        padding: 0;
    }
}