.title-our-park {
    font-size: calc(24px + .9vw);
    font-weight: 600 !important;
}

.subtitle-our-park {
    width: 50%;
    font-size: 1.1rem;
    font-weight: 500;
}

.real-time-video {
    width: 100%;
    height: 650px;
    border: none;
}

@media (max-width: 991px) {
    .subtitle-our-park {
        width: 100%;
        padding: 0 15px;
    }
}

@media (max-width: 767px) {
    .real-time-video {
        height: 450px;
    }
}