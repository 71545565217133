h2 {
    /* font-size: calc(1.8rem + .5vw); */
    font-size: calc(18px + 1.2vw);
}

.content-header {
    position: relative;
}

.text-tour {
    color: #FFF;
}

.container-tour .slick-prev,
.container-tour .slick-next {
    display: none !important;
}

h2.my-inside {
    font-size: calc(24px + .9vw);
    font-weight: 600 !important;
}

.navbar-icons {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    min-height: 85px;
    bottom: 5px;

    ul .row {
        justify-content: center;
    }

    li>span {
        color: black;
        cursor: default;
    }

    p.flex {
        display: flex;
        flex-direction: column;
        margin: 0;
        cursor: pointer;
    }
}

.viewDetailsButton {
    text-decoration: none;
    color: #aaa;
    font-size: 12px;
}

.seeMoreSection {
    & a, button {
        max-width: 270px;
        width: 100%;
        color: white;
        padding: 0;
        background-color: #707070;

        &:hover {
            color: white !important;
        }
    }
}


.bannerCallToAction {

    a,
    img {
        width: 100%;
        cursor: pointer;
    }
}

.navbar-icons .row {
    flex-wrap: nowrap;

    * {
        width: unset;
    }
}

.nav-pills {

    .col-md-4 {
        padding: 0;
        border-right: 1px solid rgba(112, 112, 112, 0.2);
    }

    .col-md-4:last-child {
        border-right: none;
    }

    li {
        margin: 0 70px;
    }


    span {
        padding: 0;
    }
}

@media (max-width: 768px) {
    .nav-pills li {
        margin: 0 35px;
    }
}

@media (max-width: 480px) {
    .nav-pills li {
        margin: 0 20px;
    }
}

/* .bannerCallToAction {
    & img {
        min-height: 170px;
    }
} */

.nav-tour {
    & .btn {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.bannerCall {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat !important;
    height: 205px;
}

@media (max-width: 813px) {
    .bannerCall {
        background-size: cover !important;
        background-position: 64% center !important;
        height: 115px;
    }

    .my-inside {
        padding-bottom: 30px;
    }
    
    .my-outside {
        padding-top: 60px;
        //margin-bottom: 80px;
    }

    .my-outside-our-park {
        padding-top: 50px;
    }
}

.atividadesDestacadas.trilhas .slick-track {
    height: 560px;
    display: flex;
    justify-content: center;
}

