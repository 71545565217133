.reservation-details{
    .modal-content .modal-body h5{
        margin: 0;
    }

    .close{
        background-color: transparent;
        border: unset;
        font-size: 25px;
    }
    
    .modal-dialog.modal-lg{
        max-width: 1024px;
    }
    .modal-content{
        width: 100% !important;
        max-height: 996px;
    }
}