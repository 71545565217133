.privacy-check {
    cursor: pointer;
    &:hover {
        opacity: .6;
    }
}

.modal-content {
    max-height: 600px;
    & .modal-header h4 {
        margin-bottom: 0 !important;
        font-size: 1.25rem !important;
    }
    & .modal-body {
        overflow-y: scroll;
        & h5 {
            margin-top: 50px;
        }
        & .link-cookie {
            &:hover {
                opacity: .6;
            }
        }
    }
    & .modal-footer {
        & .btn {
            border-radius: .25rem !important;
            //border-color: #162C59 !important;
            width: 100px;
            &:hover {
                color: #FFF;
            }
        }
    }
}

@media (max-width: 480px) {
    .fast-buy-resize .modal-content {
        max-height: 100%;
    }
}