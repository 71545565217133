@import "../../assets/sass/_variables.sass";

.page-static-caracol.bg-how-to-get-caracol {
    background-color: #fff;
    height: 43rem;
    max-height: 677px;
    padding: 1rem;

    & .row{
        height: 100%;
    }
    & .how-to-get-caracol {
        justify-content: center;
        display: flex;
        flex-direction: column;
        color: $primary;
        //max-width: 390px;
        

        & .how-to-get-top {
            display: flex;
            align-items: center;
            margin-bottom: 1.5rem;
        }

        & .how-to-get-description {
            margin-bottom: 1.5rem;
        }

        & svg {
            width: 55px;
            height: 73px;
        }

        & span {
            margin-left: 1.2rem;
            font-size: 2.5rem;
            vertical-align: top;
            font-weight: 700;
        }

        & p {
            margin-left: 0;
        }

        & .btn {
            width: 300px;
        }



    }

    & .top {
        margin-bottom: 3rem;

        & p {
            margin-left: 0;
        }
    }

    & p {
        margin-bottom: 1rem;
        margin-left: 1.5rem;
    }

    & h6 {
        font-weight: 600;
        margin-bottom: 1rem;
    }

    & ul {
        margin-bottom: 1.5rem;

        & li {
            list-style: inside;
            margin-left: 1.5rem;
            margin-bottom: .15rem;
        }
    }

    & .bottom {

        & p {
            margin-left: 0;
        }

        & ul {
            margin-bottom: 1.5rem;
            & li {
                list-style: none;
                margin-left: 0;
                margin-bottom: .15rem;
            }
        }
    }
}

@media (min-width: 1921px) {
    .bg-how-to-get-caracol {
        
        & iframe {
            max-height: 620px !important;
        }
    }
}

@media(max-width: 767px){
    .bg-how-to-get-caracol {
        
        & iframe {
            max-height: 325px !important;
        }
    }

    .page-static-caracol.bg-how-to-get-caracol .how-to-get-caracol span{
        font-size: 2rem;
    }
}