@import "../../assets/sass/_variables.sass";

.multi-locale-days .rmdp-day {
    width: 50px;
    height: 50px;
}

.rmdp-header-values {
    font-size: 18px;
}

.rmdp-week-day {
    font-size: 12px;
}

.rmdp-day-picker {
    padding: 5px;
    justify-content: space-between;
    gap: 35px;
}

.rmdp-week-day {
    color: #000 !important;
}

.rmdp-day span {
    border-radius: initial;
    bottom: 0px;
    left: 0px;
    right: 0px;
    top: 0px;
}

.calendar-input {
    padding: 0.375rem 0.75rem 0.375rem 2rem !important;
    font-size: 0.875rem;
    border-radius: 3px !important;
    border: 1px solid #d2d8dd;
}

.rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
    background-color: $primary !important;
    color: #fff;
    border-radius: 2.5px;
}

.rmdp-day.rmdp-selected span:not(.highlight) {
    background-color: $primary !important;
    box-shadow: 0 0 3px #8798ad;
    color: #fff;
}

.profile-container {
    & .rmdp-container {
        display: block !important;
    }
}

.alert-calendar {
    max-width: 368px !important;
    padding: .75rem;
    text-align: left;

    & h5 {
        font-size: 1rem;
        font-weight: 500;
        margin-bottom: 1rem;
    }

    & ul {
        padding: 0 0 0 20px;
        & li {
            list-style: outside !important;
            font-size: .75rem;

            &:first-child {
                margin-bottom: .5rem;
            }
        }
    }
}

@media (max-width: 420px){
    .rmdp-mobile .rmdp-day, .rmdp-mobile .rmdp-week-day {
        width: 47px;
        height: 47px;
    }
    .rmdp-mobile .rmdp-day-picker {
        margin-left: 0px !important;
        margin-right: 0px !important;
    }
}