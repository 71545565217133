@import '../../assets/sass/_variables.sass';

.download-center {
    & .form-select {
        width: 25%;
        border: none;
        border-bottom: 1px solid;
        border-radius: 0;
        &:focus {
            box-shadow: none;
        }
    }

    & .accordion-body {
        & a {
            color: unset;
            text-decoration: none;
            &:hover {
                opacity: .6;
                text-decoration: underline;
            }
        }
        & .row:nth-child(even) {
            background-color: #1f6a5d38;
        }
    } 

    & .accordion-button {
        border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
        background-color: $primary;
        color: #FFF;
        &:after {
            filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
        }
        &:not(.collapsed)::after {
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
            filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
        }
    }
}

@media (max-width: 767px) {
    .download-center {
        & .form-select {
            width: 50%;
        }
    }
}